import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Home from "./pages/Home";
import Login from "./pages/Login";
import withSnackbar from "./components/Snackbar";
import ApplicationView from "./pages/ApplicationView";
import Header from "./components/Header";

const RoutesConfig = ({ showSnackbar }) => {
  return(
    <Router>
      <Header showSnackbar = {showSnackbar} />
      <Routes>
        <Route index element = {<Home showSnackbar = {showSnackbar} />} />
        <Route path="/login" element = {<Login showSnackbar = {showSnackbar} />} />
        <Route path="/application/:id" element = {<ApplicationView showSnackbar = {showSnackbar} />} />
      </Routes>
    </Router>
  )
}

export default withSnackbar(RoutesConfig);