import { useState } from "react";
import { ReactComponent as Menu } from "../static/icons/menu-icon.svg";
import { ReactComponent as Cross } from "../static/icons/cross-icon.svg";
import { ReactComponent as Applications } from "../static/icons/applications-icon.svg";
import { ReactComponent as Logout } from "../static/icons/logout-icon.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { messageTypes } from "../constants";
import AuthService from "../services/auth";

const Header = ({ showSnackbar }) => {

  const [showSidebar, setShowSidebar] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  axios.interceptors.request.use(request => {
    const token = localStorage.getItem("cl_access_token");
    request.headers["Authorization"] = token;
    return request;
  })

  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if(error.response.status === 401){
      showSnackbar("Session expired ! Please login again", messageTypes.ERROR)
      setTimeout(() => {
        AuthService.logout();
        navigate("/login");
      }, 3500)
    }
    return Promise.reject(error)
  })

  return(
    <>
      <header className={`fixed-top align-items-center ${location.pathname === "/login" ? "d-none" : "d-flex"}`}>
        {
          showSidebar ?
          <Cross
            className="menu-icon"
            height={28}
            onClick = {() => setShowSidebar(false)}
          /> :
          <Menu
            className="menu-icon"
            height={28}
            onClick = {() => setShowSidebar(true)}
          />
        }
      </header>
      <div className={`sidebar-container ${showSidebar ? "show" : ""}`} onClick = {() => setShowSidebar(false)}>
        <div className="sidebar">
          <NavLink to="/" className="sidebar-link d-flex align-items-center">
            <Applications className="icon" />
            Applications
          </NavLink>
          <NavLink to="/login" className="sidebar-link d-flex align-items-center" onClick={AuthService.logout}>
            <Logout className="icon" />
            Logout
          </NavLink>
        </div>
      </div>
    </>
  )
}

export default Header;