import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApplicationService from "../services/application";
import moment from "moment";
import { ReactComponent as Eye } from "../static/icons/eye-icon.svg";
import Pagination from "../components/Pagination";

const Home = () => {

  const [applications, setApplications] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0)
  
  useEffect(() => {
    ApplicationService.getApplications(page, 10).then((res) => {
      setApplications(res.data.data)
      setCount(res.data.total_records)
    })
  }, [page])

  return(
    <div className="site-container">

      <div className="paper with-table">
        <table className="table">
          <thead>
            <tr>
              <th> Application # </th>
              <th> CIF # </th>
              <th> Customer Name </th>
              <th> Contact Number </th>
              <th> Email </th>
              <th> Appointment Date and Time </th>
              <th className="text-center"> View </th>
            </tr>
          </thead>
          <tbody className="text-uppercase">
            {
              applications.map((application) => 
                <tr key={application._id}>
                  <td> {application.id} </td>
                  <td> {application.customer_details.cif_number} </td>
                  <td> {application.customer_details.customer_name} </td>
                  <td> {application.customer_details.contact_number} </td>
                  <td> {application.customer_details.email} </td>
                  <td> {moment(application.confirmed_medical_center_details.medical_appointment_time).format("DD-MM-YYYY hh:mm A")} </td>
                  <td className="text-center">
                    <Link to={`/application/${application.id}`} className = "d-inline-block">
                      <Eye className="view-icon" />
                    </Link>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>

      <Pagination
        count = {count}
        page = {page}
        handleChangePage = {(pageNo) => setPage(pageNo)}
      />
    </div>
  )
}

export default Home;