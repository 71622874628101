import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApplicationService from "../services/application";
import _ from "lodash";
import { medicalTests, messageTypes } from "../constants";
// import DownloadDocuments from "../components/DownloadDocuments";
import { ReactComponent as Plus } from "../static/icons/plus-icon.svg";
import { ReactComponent as Check } from "../static/icons/checkmark-icon.svg";
import DownloadService from "../services/download";
import { saveAs } from "file-saver";

const ApplicationView = ({ showSnackbar }) => {

  const [data, setData] = useState({});
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [reportFile, setReportFile] = useState(undefined);
  const [isDownloadingMtrf, setIsDownloadingMtrf] = useState(false);

  const params = useParams();
  const id = params.id;

  const getData = () => {
    ApplicationService.getApplication(id).then((res) => {
      const data = res.data.data;

      setData(data);
      setDataLoaded(true);
      if(!data.is_viewed_by_underwriter){
        ApplicationService.updateApplication(id, {is_viewed_by_underwriter : true})
      }
    })
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [])

  const Details = ({title, field, suffix = "", isDate, dateFormat}) => {
    let value = _.get(data, field);
    if(isDate){
      value = moment(value).format(dateFormat)
    }
    return(
      <div className="detail-container">
        <div className="title">
          {title}
        </div>
        <div className="value">
          {value} {suffix}
        </div>
      </div>
    )
  }

  const uploadReport = () => {
    const data = new FormData();
    data.append("title", "Medical Test Report");
    data.append("document", reportFile);
    data.append("document_field", "medical_documents");
    ApplicationService.uploadDocument(id, data).then((res) => {
      showSnackbar("Medical Test Report uploaded successfully !", messageTypes.SUCCESS);
      getData();
    });
  }

  const downloadMtrf = () => {
    setIsDownloadingMtrf(true);
    DownloadService.downloadDocument("mtrf", data.id).then((res) => {
      saveAs(res.data, `${data.id}-MTRF.pdf`);
      setIsDownloadingMtrf(false);
    })
  }

  return(
    <>
      {
        isDataLoaded &&
        <div className="site-container application-details-container">

          <div>
            <div className="paper">
              <Details title = "Application No" field="id" />
              <Details title = "Customer Name" field="customer_details.customer_name" />
              <Details title = "Gender" field="customer_details.gender" />
              <Details title = "Contact Number" field="customer_details.contact_number" />
              <Details title = "Email" field="customer_details.email" />
              <Details title = "Civil ID" field="customer_details.civil_id" />
              <Details title = "Height" field="customer_details.height" suffix="cms" />
              <Details title = "Weight" field="customer_details.weight" suffix="kgs" />
              <Details title = "Date of Birth" field="customer_details.dob" isDate dateFormat="DD-MM-YYYY" />
              <Details title = "BMI" field="bmi" />
            </div>
          </div>

          <div>
            <div className="paper">
              <div className="paper-title">
                Medical tests to be done
              </div>

              <div className="medical-test-container d-flex">
                <div className="title flex-grow-1">
                  Medical Examination Report
                </div>
                <div className="value text-uppercase flex-shrink-0 d-flex align-items-center justify-content-center">
                  {data.medical_tests?.includes(medicalTests.MER) ? "Yes" : "No"}
                </div>
              </div>

              <div className="medical-test-container d-flex">
                <div className="title flex-grow-1">
                  Urine Analysis
                </div>
                <div className="value text-uppercase flex-shrink-0 d-flex align-items-center justify-content-center">
                  {data.medical_tests?.includes(medicalTests.URINE_ANALYSIS) ? "Yes" : "No"}
                </div>
              </div>

              <div className="medical-test-container d-flex">
                <div className="title flex-grow-1">
                  Lab 1
                </div>
                <div className="value text-uppercase flex-shrink-0 d-flex align-items-center justify-content-center">
                  {data.medical_tests?.includes(medicalTests.LAB_1) ? "Yes" : "No"}
                </div>
              </div>

              <div className="medical-test-container d-flex">
                <div className="title flex-grow-1">
                  Lab 2
                </div>
                <div className="value text-uppercase flex-shrink-0 d-flex align-items-center justify-content-center">
                  {data.medical_tests?.includes(medicalTests.LAB_2) ? "Yes" : "No"}
                </div>
              </div>

              <div className="medical-test-container d-flex">
                <div className="title flex-grow-1">
                  ECG
                </div>
                <div className="value text-uppercase flex-shrink-0 d-flex align-items-center justify-content-center">
                  {data.medical_tests?.includes(medicalTests.ECG) ? "Yes" : "No"}
                </div>
              </div>

              <div className="medical-test-container d-flex">
                <div className="title flex-grow-1">
                  TMT
                </div>
                <div className="value text-uppercase flex-shrink-0 d-flex align-items-center justify-content-center">
                  {data.medical_tests?.includes(medicalTests.TMT) ? "Yes" : "No"}
                </div>
              </div>

              <div className="medical-test-container d-flex">
                <div className="title flex-grow-1">
                  HIV & HCV
                </div>
                <div className="value text-uppercase flex-shrink-0 d-flex align-items-center justify-content-center">
                  {data.medical_tests?.includes(medicalTests.HIV) ? "Yes" : "No"}
                </div>
              </div>

              <div className="medical-test-container d-block">
                <div className="title">
                  Others
                </div>
                <div className="value">
                  {data.confirmed_medical_center_details.medical_test_additional_information || "NO"}
                </div>
              </div>

            </div>

            <div className="paper">
              <div className="paper-title">
                Download MTRF
              </div>

              <div>
                <button
                  onClick={downloadMtrf}
                  disabled = {isDownloadingMtrf}
                >
                  {isDownloadingMtrf ? "Downloading . . ." : "Download MTRF"}
                </button>
              </div>
            </div>
          </div>

          <div>
            <div className="paper">
              <div className="paper-title">
                Upload Medical test Report
              </div>

              <div className="d-flex align-items-center flex-column">
                <label className="upload-label d-flex align-items-center justify-content-center">
                  {
                    data.medical_documents ? 
                    <>
                      <Check />
                    </> :
                    <>
                      <input
                        type="file"
                        className="d-none"
                        onChange={(e) => {
                          setReportFile(e.target.files[0])
                          e.target.value = ""
                        }}
                      />

                      <Plus />
                    </>
                  }
                </label>

                {
                  !data.medical_documents &&
                  <button
                    className="button-sm"
                    onClick={uploadReport}
                    disabled = {!reportFile}
                  >
                    Upload
                  </button>
                }
              </div>

            </div>
          </div>

        </div>
      }
    </>
  )
}

export default ApplicationView;