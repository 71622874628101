import axios from "axios";
import config from "./config";

const baseUrl = config.baseUrl;

const application = {};

application.create = async (data) => {
  const url = `${baseUrl}/create-application`;
  const res = axios({
    url,
    method : "POST",
    data : data
  });

  return res;
}

application.getApplications = async (page, limit) => {
  const url = `${baseUrl}/get-applications`;

  let params = {};

  if(page !== undefined && limit !== undefined){
    params = {
      page,
      limit
    }
  }
  const res = axios({
    url,
    params
  });
  return res;
}

application.getApplication = async (id) => {
  const url = `${baseUrl}/get-application/${id}`;
  const res = axios({ url });
  return res;
}

application.updateApplication = async (id, data, route = "application") => {
  const url = `${baseUrl}/${route}/${id}`;
  const res = axios({
    url,
    method : "PUT",
    data
  });
  return res;
}

application.uploadDocument = async (id, data) => {
  const url = `${baseUrl}/upload-document/${id}`;
  const res = axios({
    url,
    method : "POST",
    headers : {
      'Content-Type' : "multipart/form-data"
    },
    data : data
  });
  return res;
}
export default application;